<template>
  <div class="dashboard-pop-edit-container">
    <a-modal
      title="修改备注"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
      width="600px"
    >

<a-form
        :form="form"
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 14 }"
        @submit="handleSubmit"
      >

        <a-form-item label="备注内容">
        <a-input
          v-decorator="formDecorators.value"
        />
      </a-form-item>
      </a-form>

      <a-table
          :columns="columns"
          :data-source="dataSource"
          :loading="loading"
          size="middle"
        >

      </a-table>

    </a-modal>
  </div>
</template>

<script>

export default {
  data() {
    return {
      confirmLoading: false,
      loading: false,
      form: this.$form.createForm(this, { name: "coordinated" }),
      menuList:[],
      list:[],
      columns:[
          {
            title: "备注人uid",
            dataIndex: 'admin_id'
          },
          {
            title: "备注内容",
            width: 100,
            dataIndex: 'remarks'
          },
          {
            title: "备注时间",
            dataIndex: 'created_at'
          }
        ]
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    row: {
      type: Object,
      default: ()=>{
        return {}
      },
    },
    menus: {
      type: Array,
      default:()=>{
        return []
      }
    },
    dataSource: {
      type: Array,
      default:()=>{
        return []
      }
    },
  },
  computed: {
    formDecorators() {
      let data = JSON.parse(JSON.stringify(this.row));

      return {
        value: [
          "value",
          {
            initialValue: data && data.value,
            rules: [{ required: true, message: "请填写备注" }],
            validateTrigger: ["blur"],
          },
        ],
        // type: [
        //   "type",
        //   {
        //     initialValue: data && data.type
        //   },
        // ]
      };
    },
  },
  created(){
    
  },
  mounted() {
    
  },
  filters:{

  },
  methods: {

    // 图标选择
    handleIconChange (icon) {
      this.icon = icon
      let fieldsValue = {
        icons: icon
      }
      this.form.setFieldsValue(fieldsValue)
    },

    handleOk(){
      this.confirmLoading = true;
      this.form.validateFields((err, values) => {
        if (!err) {
          values.id=this.row.id
          values.type=1
          this.$api.post(`/v2/anchorDataGame/addRemarks`, values)
            .then((resp) => {
              if (resp.code == 200) {
                this.$message.success("操作成功");
                this.$emit("handleOk");
              } else {
                this.$message.error(resp.message);
              }
              this.confirmLoading = false;
            })
            .catch((e) => {
              this.confirmLoading = false;
              throw e;
            });
        }else{
          this.confirmLoading = false;
        }
        })
    },
    handleCancel(){
      this.$emit('handleCancel')
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log("Received values of form: ", values);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.dashboard-pop-edit-container {
}

.list {
  display: flex;
  margin-bottom: 30px;
  .list-left {
    display: flex;
    width: 350px;
    align-items: center;
  }
  .list-right {
    display: flex;
    align-items: center;
    margin-left: 50px;
  }
}

.label {
  width: 100px;
  font-size: 20px;
  color: #333333;
  display: flex;
  justify-content: space-between;
}
.value {
  font-size: 20px;
  color: #199aec;
  display: flex;
}
.l-height{
  line-height: 30px;
}
@media screen and(max-width:750px) {
  
.list {
  display: block;
  margin-bottom: 0px;
  .list-left {
    display: flex;
    width: 350px;
    align-items: center;
    margin-bottom: 15px;
  }
  .list-right {
    display: flex;
    align-items: center;
    margin-left: 0px;
    margin-bottom: 15px;
  }
}

.label {
  width: 100px;
  font-size: 16px;
  color: #333333;
  display: flex;
  justify-content: space-between;
}
.value {
  font-size: 16px;
  color: #199aec;
  display: flex;
}
.l-height{
  line-height: 30px;
}
}

</style>
