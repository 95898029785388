<template>
  <div class="anchor-pop-edit-container">
    <a-modal
      title="主播详情"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
      width="500px"
    >

    <a-descriptions title=""  :column="1"  v-if="row"   >
      <a-descriptions-item label="是否新人">
        {{row.is_new_anchor == 1?'是':'否' }}
      </a-descriptions-item>
      <a-descriptions-item label="有效天">
        {{row.effective_day}}
      </a-descriptions-item>
      <a-descriptions-item label="最后开播时间">
        {{row.last_broadcast_time}}
      </a-descriptions-item>
      <a-descriptions-item label="最后观看">
        {{row.last_watch_num}}
      </a-descriptions-item>
      <a-descriptions-item label="最高观看">
        {{row.max_highest_watch}}
      </a-descriptions-item>
      <a-descriptions-item label="大航海数量更新时间">
        {{row.upd_guard_num_time}}
      </a-descriptions-item>
      <a-descriptions-item label=" 大航海数量">
        {{row.guard_num}}
      </a-descriptions-item>
      <a-descriptions-item label="查询">
        {{row.admin_uname | handleData }}
      </a-descriptions-item>
      <a-descriptions-item label="联系方式">
        {{row.contact_way | handleData }}
      </a-descriptions-item>
      <a-descriptions-item label="入会">
        {{row.initiate_status==1?'已入会':'未入会' }}
      </a-descriptions-item>
      <a-descriptions-item label="备注">
        <div v-if="row.remarks.length==0">无</div>
        <div v-else> {{row.remarks[row.remarks.length-1].remarks}}</div>
      </a-descriptions-item>

    </a-descriptions>


    </a-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      confirmLoading: false,
      isNote:false,
      isContact:false,
      row:null
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    item:{
      type:Object,
      default:()=>{
        return {}
        }
    }
  },
  filters: {
    handleData(data) {
      if (!data){
        data='--'
      }
      return data;
    }
  },
  computed: {},
  mounted() {
    console.log('eee',this.row)
    this.getDetail()
  },
  methods: {
    getDetail(){
      this.$api.get(`/v2/anchorDataInvented/find?id=${this.item.id}`)
            .then((resp) => {
              if (resp.code == 200) {
                this.row=resp.data
              } else {
                this.$message.error(resp.message);
              }
            })
            .catch((e) => {
              throw e;
            });
    },
    handleOk(e) {
      this.$emit("handleOk");
    },
    handleCancel(e) {
      this.$emit("handleCancel");
    }

  },
};
</script>

<style lang="less" scoped>
.anchor-pop-edit-container {
}

.list {
  display: flex;
  margin-bottom: 30px;
  .list-left {
    display: flex;
    width: 350px;
    align-items: center;
  }
  .list-right {
    display: flex;
    margin-left: 50px;
  }
}

.label {
  width: 100px;
  font-size: 20px;
  color: #333333;
  display: flex;
  justify-content: space-between;
}
.value {
  font-size: 20px;
  color: #199aec;
  display: flex;
}
</style>
