<template>
    <div class="anchor-container">
      <!-- <search-card /> -->
      <table-card style="margin-top:30px" />
    </div>
</template>

<script>

import { 
// searchCard,
tableCard
} from '@/components/anchor/invent'
export default {
  name: "anchor",
  components: {
    // searchCard,
    tableCard
  },
  data() {
    return {

    };
  },
  computed: {
  },
  mounted(){

  },
  methods: {

  }
};
</script>

<style lang="less" scoped>
.anchor-container {
  width: 100%;
}
</style>
